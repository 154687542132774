import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { useProfilePhoto } from "../custom-hooks/useProfilePhoto"

const temporaryData = [
  "Natal: Kasih yang Tidak Berkesudahan",
  "Semakin Bersyukur, Bersaudara, dan Berbelarasa",
  "Dirgahayu Gereja Santa Maria Regina, Paroki Bintaro Jaya ke - 7",
]
const BlogTemplate = () => {
  const { tempImage } = useStaticQuery(graphql`
    {
      tempImage: file(relativePath: { eq: "about/profilRomo/RmLucky.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <React.Fragment>
      <SEO title="Renungan" />
      <Layout>
        <div className="container">
          <div className="text-left text-md-center">
            <Image data={tempImage} />
            <div className="my-3">
              <h1>Semakin Bersyukur, Bersaudara, dan Berbelarasa</h1>
              <blockquote className="blockquote">
                <h4>Sewindu SanMaRe</h4>
              </blockquote>
              <div className="d-flex align-items-center justify-content-start justify-content-md-center">
                <Img
                  fixed={useProfilePhoto()}
                  style={{ maxWidth: "48px", maxHeight: "48px" }}
                />
                <div>
                  <p className="m-0 ml-2 text-muted">
                    Rm. Lucky · {new Date().toDateString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="blog-template-paragraph">
            <p>
              Beberapa waktu yang lalu, kita memperingati Peringatan Wajib Santa
              Maria Ratu (Santa Maria Regina). Tepatnya peri-ngatan itu
              dirayakan setiap 22 Agustus. Persis 8 tahun yang lalu, gereja kita
              menjadi paroki yang ke-61 di Keuskupan Agung Jakarta. Dan
              bebe-rapa waktu lalu beredar video rekaman kotbah Bapa Uskup Agung
              Jakar-ta dalam misa peresmian Paroki Bintaro Jaya, Gereja Santa
              Maria Regina (SanMaRe).
            </p>
            <p>
              Masihkah kita mengingat intisari kotbah Bapa Uskup Agung Jakarta,
              Mgr. Ignatius Suharyo, dalam misa tersebut? Sekurang-kurangnya
              dari rekaman yang saya dengar intisarinya adalah Bapa Uskup
              mengajak umat SanMaRe untuk membangun sebuah persekutuan dalam
              semangat persaudaraan.
            </p>
            <p>
              Lebih lanjut beliau memaknai persekutuan yang dilandasi
              persaudaraan sejati terungkap dalam kesadaran akan panggilan dan
              perutusan hidup masing-masing. Melalui kesadaran ini, persaudaraan
              menjadi tempat perjumpaan yang menguatkan. Dengan demikian
              persekutuan persaudaraan itu menjadi wadah untuk senantiasa
              berpengharapan akan kasih Allah yang tiada henti.
            </p>
            <p>
              Becermin dari inspirasi ini saya mengajak kita semua untuk
              bertanya apakah kita telah membangun semangat dasar persaudaraan
              ini? Menjadikan gereja kita sebagai rumahku, gerejaku, dan
              tanggungjawabku? Mungkin banyak variasi jawaban dari pertanyaan
              ini. Namun saya berharap jawaban kita adalah searah dengan
              semangat dasar untuk membangun persekutuan dalam semangat
              persaudaraan sejati sebagaimana dikotbahkan Bapa Uskup.
            </p>
            <p>
              Dalam suatu pertemuan imam-imam, saya pernah diminta untuk membuat
              tagline yang menjadi cerminan, spirit yang menggambarkan Gereja
              SanMaRe. “Gereja SanMaRe adalah gereja cluster”, berarti gereja
              yang senantiasa berkembang, berdinamika dan bertumbuh. Makna
              cluster menurut “Kamusku” online berarti sekelompok atau
              sekumpulan orang atau sesuatu yang tumbuh atau muncul berdekatan.
            </p>
            <p>
              Dari terminologi dan tagline gereja cluster ini menyiratkan
              beberapa makna. Pertama, gereja yang berarti paguyuban umat
              beriman senantiasa bertumbuh dalam iman; Kedua, paguyuban ini
              mengarah pada semangat saling bersaudara; dan Ketiga, persaudaraan
              ini juga membidani lahirnya semangat berbelarasa, gotong-royong
              dan saling membantu. Tak ayal apabila Sewindu SanMaRe mengangkat
              tema semakin bersyukur, bersaudara, dan berbelarasa. Sebab inilah
              cerminan, spirit dan semoga menjadi karakter kita
            </p>
            <p>
              Akhirnya, saya ingin mengucapkan proficiat untuk kita semua dalam
              peringatan sewindu peresmian Paroki Bintaro Jaya. Semoga di usia
              yang kedelapan ini, iman kita semakin kokoh, persekutuan kita
              menjadi oase segar yang selalu dirindukan, dan semangat belarasa
              kian memancar dalam kesaksian hidup kita.
            </p>
            <p>Viva SanMaRe-ku…</p>
            <div className="d-flex align-items-center justify-content-start my-5">
              <Img
                fixed={useProfilePhoto()}
                style={{ maxWidth: "96px", maxHeight: "96px" }}
              />
              <div className="ml-2 text-muted">
                <h4 className="m-0 ">Ditulis oleh</h4>
                <p className="m-0 ">Rm. Lucky · {new Date().toDateString()}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-light">
          <div className=" container my-5">
            <h3>Baca Renungan lainnya</h3>
            <div className="row">
              {temporaryData.map(blog => {
                return (
                  <div className="col-12 col-md-6 col-lg-4 my-3">
                    <Link
                      to="/blog-template"
                      className="text-decoration-none text-dark h-100 d-flex flex-column"
                    >
                      <Image />
                      <p className="text-muted m-0">Kategori</p>
                      <h4>{blog}</h4>
                      <p className="text-muted ">Sub Judul</p>
                      <div className="flex-grow-1" />
                      <div className="d-flex align-items-center">
                        <Img
                          fixed={useProfilePhoto()}
                          style={{ maxWidth: "24px", maxHeight: "24px" }}
                        />
                        <div>
                          <p className="m-0 ml-2 text-muted small">
                            Rm. Lucky · {new Date().toDateString()}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}
export default BlogTemplate
